* {
  margin: 0;
  padding: 0;
}
.content {
  padding: 3rem 20%;
}
.header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 8px;
  border-bottom: 1px solid;
}
.name {
  font-size: 1.5rem;
  font-weight: 600;
}
.links {
  display: flex;
  gap: 8px;
  margin-bottom: 2px;
  font-style: italic;
  color: #8b7cba;
}
.commented {
  color: #b1aebb;
  font-style: italic;
  margin-top: 16px;
  margin-bottom: 16px;
}
a {
  color: inherit;
}
.declare {
  display: inline;
}
.action {
  background-color: #444054;
  margin-top: calc(12px + 1rem);
  padding: 4px 0px;
  width: fit-content;
}
.container {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.action:hover {
  background-color: cornsilk;
  color: #8b7cba;
  cursor: pointer;
}
.job {
  padding: 8px;
}
.job:hover {
  background-color: #444054;
  cursor: pointer;
}
#inkblot {
  color: #b6b9f4;
  text-decoration: none;
}
#redthread {
  color: red;
  text-decoration: none;
}
#tdlab {
  color: green;
  text-decoration: none;
}
@media only screen and (max-width: 600px) {
  .links {
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
  }
}
@media only screen and (max-width: 280px) {
  .links {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
  }
  .header {
    display: block;
  }
}
table,
th,
td {
  border: 1px solid white;
  border-collapse: collapse;
  padding: 12px;
}
